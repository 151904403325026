<template>
  <div class="animated fadeIn">
    <b-card>
      <h4><i class="icon-layers"></i> All Invoices</h4>
      <hr />
      <div class="row my-3">
        <div class="col-md-4">
          <h5>Project</h5>
          <v-select
            :clearable="false"
            class="project-select"
            v-model="project"
            label="projectname"
            :options="projectData"
            :value="projectid"
            placeholder="Search For Project"
            @input="loadInvoices()"
          ></v-select>
        </div>
        <div v-if="project" class="col-md-4 mt-3 mt-md-0">
          <h5>Unprocessed / Processed</h5>
          <v-select
            :clearable="false"
            class="project-select"
            v-model="type"
            label="type"
            :options="typeOptions"
            @input="loadInvoices()"
          ></v-select>
        </div>
        <div v-if="project" class="col-md-4">
          <h5>&nbsp;</h5>
          <button @click="loadInvoices()" class="btn btn-primary">
            <i class="fa fa-sync-alt"></i>
          </button>
        </div>
      </div>
      <v-client-table
        class="inv-status-table table-responsive wss-table"
        ref="invoiceTable"
        :columns="columns"
        :data="invoiceData"
        :options="options"
        :theme="theme"
        id="dataTable"
      >
        <span slot="total" slot-scope="props">
          {{ formatPrice(props.row.total) }}
        </span>

        <div slot="quickpay_total" slot-scope="props">
          <span
            v-if="
              props.row.quickpay_total > 0 &&
              $moment(props.row.endtday) > $moment()
            "
          >
            {{ formatPrice(props.row.quickpay_total) }}
          </span>
        </div>

        <div slot="inv_passed" slot-scope="props">
          <!-- <InvoiceStatus :invoice="props.row"></InvoiceStatus> -->
          <div v-if="props.row.accountant_paid === 0">
            <div v-if="props.row.inv_passed === 9">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By WSS (Submit Again)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 17">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Accountant
              </button>
            </div>

            <div v-if="props.row.inv_passed === 8">
              <button class="btn-sm btn-warning btn-pm">
                <i class="fa fa-question"></i> Pending By Project Manager
              </button>
            </div>

            <div v-if="props.row.inv_passed === 7">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 6">
              <button class="btn-sm btn-warning btn-management">
                <i class="fa fa-question"></i> Pending By Higher Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 5">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Project Manager (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 0">
              <button class="btn-sm btn-warning">
                <i class="fa fa-question"></i> Pending By WSS
              </button>
            </div>

            <div v-if="props.row.inv_passed === 2">
              <button class="btn-sm btn-blue">
                <i class="fa fa-save"></i> Draft
              </button>
            </div>

            <div v-if="props.row.inv_passed === 11">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 13">
              <button class="btn-sm btn-warning btn-management">
                <i class="fa fa-question"></i> Pending By Higher Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 14">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 21">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 23">
              <button class="btn-sm btn-warning btn-management">
                <i class="fa fa-question"></i> Pending By Higher Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 24">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 31">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 33">
              <button class="btn-sm btn-warning btn-management">
                <i class="fa fa-question"></i> Pending By Higher Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 34">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 41">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 43">
              <button class="btn-sm btn-warning btn-management">
                <i class="fa fa-question"></i> Pending By Higher Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 44">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 51">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 54">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>
          </div>
          <div v-if="props.row.accountant_paid === 1">
            <button class="btn-sm btn-success">
              <i class="fa fa-check"></i> Paid
            </button>
          </div>
        </div>
        <span slot="creation_date" slot-scope="props">{{
          props.row.creation_date | moment("MM-DD-YYYY")
        }}</span>
        <div slot="invoice_to" slot-scope="props">
          {{props.row.invoice_to ? lodash.uniq(rtnest_users.filter(x => props.row.invoice_to.split(',').includes(x.email)).map(y => `${y.fname} ${y.lname}`)).join(', ') : ''}}
        </div>

        <div slot="invnum" slot-scope="props">
          <a
            href="javascript:void(0)"
            v-if="props.row.inv_passed != 0"
            @click="setTheInvoice(props.row, 0)"
            >{{ props.row.invnum }}</a
          >
          <a
            href="javascript:void(0)"
            v-if="props.row.inv_passed === 0"
            @click="setTheInvoice(props.row, 1)"
            >{{ props.row.invnum }}</a
          >
          <p class="mb-0 d-sm-block d-md-none">
            {{ props.row.creation_date | moment("MM-DD-YYYY") }}
          </p>
        </div>

        <div slot="actions" slot-scope="props">
          <button
            v-b-modal.history
            @click="sendHistory(props.row)"
            class="btn-sm btn-secondary"
          >
            <i class="fa fa-history"></i>
          </button>
        </div>
      </v-client-table>
      <b-modal
        ok-only
        id="history"
        ref="history"
        :title="'Invoice #' + history.invnum"
        title-tag="h2"
        centered
        size="xl"
      >
        <InvoiceHistory v-bind:history="history" v-bind:isMounted="true"></InvoiceHistory>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import { ClientTable } from "vue-tables-2";
import InvoiceHistory from "./../invoice/InvoiceHistory";

Vue.use(ClientTable);

export default {
  name: "Wss",
  components: {
    ClientTable,
    InvoiceHistory,
  },
  data() {
    return {
      checkedRows: [],
      columns: [
        "inv_passed",
        "invnum",
        "ticketnum",
        "vendor_invoice_number",
        "vcn",
        "invoice_to",
        "creation_date",
        "total",
        "quickpay_total",
        "actions",
      ],
      invoiceData: [],
      projectData: [],
      project: [],
      invoiceid: 0,
      projectid: 0,
      history: [],
      type: {
        type: "Unprocessed",
      },
      typeOptions: [
        {
          type: "All",
        },
        {
          type: "Processed",
        },
        {
          type: "Unprocessed",
        },
      ],
      wss_note: 0,
      tax_percentage: 0,
      options: {
        initFilters: {
          GENERIC: this.$session.get("query"),
        },
        orderBy: this.$session.get("orderBy"),
        initialPage: this.$session.get("page"),
        headings: {
          inv_passed: "Status",
          actions: "History",
          invnum: "Invoice #",
          creation_date: "Submitted Date",
          vcn: "Company Name",
          amount: "Amount",
          invoice_to: "Invoice To",
          quickpay_total: "Quickpay",
          ticketnum: "Field Ticket #",
          vendor_invoice_number: "Vendor Invoice #",
        },
        sortable: [
          "wss_stat",
          "invnum",
          "vcn",
          "invoice_to",
          "creation_date",
          "total",
          "quickpay_total",
        ],
        filterable: [
          "wss_stat",
          "invnum",
          "ticketnum",
          "vendor_invoice_number",
          "vcn",
          "vfname",
          "vlname",
          "invoice_to",
          "creation_date",
          "total",
          "quickpay_total",
        ],
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
      },
      theme: "bootstrap4",
      template: "default",
      useVuex: false,
      wssId: "",
      rtnest_users: [],
    };
  },
  methods: {
    setTheInvoice(props, setInvoice) {
      this.setInvoice(props, setInvoice, this.$refs.invoiceTable);

      if (setInvoice == 0) {
        this.$router.push({
          name: "WSS Invoice",
          path: "/wss/invoice",
          params: { invoice: props, set: setInvoice },
        });
      }
      if (setInvoice == 1) {
        this.$router.push({
          name: "WSS Invoice",
          path: "/wss/invoice",
          params: { invoice: props, set: setInvoice },
        });
      }
    },
    sendHistory(history) {
      this.history = history;
    },
    loadProjects() {
      this.$http.get("/wss/get/projects").then((response) => {
        this.projectData = response.data.result;
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async loadInvoices() {
      this.$session.set("project", this.project);
      this.$session.set("type", this.type.type);

      if (this.project.projectid === undefined) {
        return true;
      }

      if (this.type.type === "Unprocessed") {
        this.$http
          .get("/wss/get/unprocessed_invoices/" + this.project.projectid)
          .then((response) => {
            this.error = true;
            var data = response.data.result;
            this.invoiceData = data
              .sort(function (b, a) {
                return (
                  b.quickpay - a.quickpay || b.creation_date - a.creation_date
                );
              })
              .reverse();
          });
      }
      if (this.type.type === "Processed") {
        this.$http
          .get("/wss/get/processed_invoices/" + this.project.projectid)
          .then((response) => {
            var data = response.data.result;
            this.invoiceData = data
              .sort(function (b, a) {
                return (
                  b.quickpay - a.quickpay || b.creation_date - a.creation_date
                );
              })
              .reverse();
          });
      }
      if (this.type.type === "All") {
        const that = this;
        this.$http
          .get("/wss/get/processed_invoices/" + this.project.projectid)
          .then((response) => {
            this.invoiceData2 = response.data.result;
            that.$http
              .get("/wss/get/unprocessed_invoices/" + this.project.projectid)
              .then((response) => {
                this.invoiceData = this.invoiceData2
                  .concat(response.data.result)
                  .reverse();
              });
          });
      }
      this.invoiceData = this.invoiceData.filter((val, i) => {
        if (val.invoice_to) {
          const allowed_emails = val.invoice_to.split(',');
          allowed_emails.includes(this.wssId);
        } else {
          return true;
        }
      });

      const invoice_id = this.invoiceData.map((val) => val.invoiceid);
      const qbyte_response = await this.$http.get(
        "/accountant/qbyte/invoices/inovoiceData/?invoice_id=" + invoice_id
      );
      qbyte_response.data.qbyteData.forEach((val, i) => {
        if (
          val.total !== undefined &&
          this.invoiceData.some((el) => el.invoiceid === val.invoice_id)
        ) {
          this.invoiceData[i].total = val.total;
          this.invoiceData[i].quickpay_total = val.quickpay_total;
        }
      });
    },
  },
  computed: {},
  mounted() {
    this.$http
      .get("/wss/get/profile")
      .then((response) => {
        this.wssId = response.data.email;
      })
      .catch((error) => {});
    this.$http
      .get("/wts/get/profile/rtnest_users")
      .then((response) => {
        this.rtnest_users = [...response.data.wts_result, ...response.data.wss_result];
      })
    .catch((error) => {});  
    this.loadProjects();
    if (this.$session.get("project") && this.$session.get("type")) {
      this.project = this.$session.get("project");
      this.type.type = this.$session.get("type");
      this.loadInvoices();
    }
  },
};
</script>
